<div class="d-flex flex-column justify-content-center align-items-center h-100">
  <div [style.padding-top.em]="size() === 'lg' ? 6 : 0">
    <fa-stack class="with-pointer" [size]="stackSize" (click)="createStore()">
      <fa-duotone-icon
        class="themed-store"
        [icon]="['fad', 'store']"
        stackItemSize="2x"
        primaryColor="#305F72"
        secondaryColor="#568EA6"
      ></fa-duotone-icon>
      <fa-icon
        [icon]="['far', 'plus-circle']"
        stackItemSize="1x"
        transform="up-22 right-20"
        class="text-info"
      ></fa-icon>
    </fa-stack>
  </div>
  @switch (size()) {
    @case ('lg') {
      <div class="mat-display-2 mt-4 fw-lighter with-pointer" (click)="createStore()">
        Tilføj din første forretning...
      </div>
    }
    @case ('md') {
      <div class="mat-display-1 mt-4 fw-lighter with-pointer" (click)="createStore()">
        Tilføj din første forretning...
      </div>
    }
    @case ('sm') {
      <div class="mat-h2 mt-3 fw-lighter with-pointer" (click)="createStore()">Tilføj forretning</div>
    }
  }
</div>
