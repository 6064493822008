import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sb-no-store',
  templateUrl: './no-store.component.html',
  standalone: false, // eslint-disable-line
})
export class NoStoreComponent {
  private _router = inject(Router);

  readonly size = input<'lg' | 'md' | 'sm'>(undefined);

  get stackSize() {
    switch (this.size()) {
      case 'lg':
        return '5x';
      case 'md':
        return '4x';
      case 'sm':
        return '2x';
      default:
        return '1x';
    }
  }

  createStore() {
    this._router.navigate(['/', 'stores', 'sales-platform']);
  }
}
